<template>
  <div>
    <div class="page-404">
      <div>
        <h1>404: Page Not Found</h1>
        <h3>
          The page you requested cannot be found.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  components: {
  }
}
</script>

<style lang="scss" scoped>
  .page-404 {
    display: flex;
    min-height: 80vh;
    align-items: center;
    justify-content: center;
    padding: 25px;
  }
</style>
